import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Spinner } from "reactstrap";
import InfoMessage from "./InfoMessage";
import { requestDemoInfo } from "../../constants/dealers";
import { Helmet } from "react-helmet";

interface RequestDemoModalProps {
  isOpen: boolean;
  toggle: (event: React.MouseEvent | React.KeyboardEvent) => void;
  closeModal: () => void;
}

const RequestDemoModal = ({ isOpen, toggle, closeModal }: RequestDemoModalProps) => {
  const [isFormLoaded, setIsFormLoaded] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsFormLoaded(false); 
      setTimeout(() => {
        setIsFormLoaded(true);
      }, 500); 
    } else {
      setIsFormLoaded(false);
    }
  }, [isOpen]);

  return (
    <React.Fragment>
      <Helmet defer={false}>
        <script type="text/javascript" async defer src="https://js.hsforms.net/forms/embed/48528424.js"></script>
      </Helmet>
      <Modal className="custom-modal" isOpen={isOpen} toggle={toggle} centered fade={true}>
        <div className="custom-modal-header">
          <button type="button" className="btn-close ms-auto p-2" aria-label="Close" onClick={closeModal} />
          <h1 className="title">Request a demo</h1>
        </div>
        <ModalBody className="custom-modal-body">
          <InfoMessage description={requestDemoInfo.description} />
          {isFormLoaded ? 
            <div
              className="hs-form-frame"
              data-region="na1"
              data-form-id="9ba92e7a-baf7-414b-98ea-c2b8620c4437"
              data-portal-id="48528424"
           /> : <Spinner color="primary" className="d-flex mx-auto" />}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default RequestDemoModal;
